"use client";

import React, { useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { useServerInsertedHTML } from "next/navigation";
import {
  ThemeProvider,
  DefaultTheme,
  ServerStyleSheet,
  StyleSheetManager,
} from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

interface StyledComponentsRegistryProps {
  children: React.ReactNode;
}

function StyledComponentsRegistry({ children }: StyledComponentsRegistryProps) {
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return <>{styles}</>;
  });

  if (typeof window !== "undefined") return <>{children}</>;

  return (
    <StyleSheetManager
      sheet={styledComponentsStyleSheet.instance}
      shouldForwardProp={shouldForwardProp}
    >
      {children}
    </StyleSheetManager>
  );
}

interface StyledProviderProps {
  children: React.ReactNode;
  theme: DefaultTheme;
}

export function StyledProvider(props: StyledProviderProps) {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider {...props} />
    </StyledComponentsRegistry>
  );
}
