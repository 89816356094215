import { type DefaultTemplate } from "./default";

export type TemplateName =
  | "default"
  | "arab-hardware"
  | "farah-omar"
  | "modern"
  | "black-and-white";

export const StoreTemplate = (function () {
  const templateMapper: { [key: string]: DefaultTemplate } = {};
  const isLoadedHash = new Set<string>();
  let templateName: string | undefined = undefined;

  const Actions = {
    load: (
      template: TemplateName,
      page: string,
      elements: Partial<DefaultTemplate>
    ) => {
      templateName = template;
      const key = `${template}_${page}`;
      if (!isLoadedHash.has(key)) {
        isLoadedHash.add(key);
        templateMapper[template] = { ...templateMapper[template], ...elements };
      }
      return templateMapper[template];
    },

    get: () => {
      return templateMapper[templateName!];
    },
  };

  return Actions;
})();
