"use client";

import { CookieEnum, getCookie } from "@/shared/utils/cookie";
import React, { useEffect } from "react";
import { tryLogin } from "../utils";
import { userVar } from "../utils/reactive-variable";

interface TryLoginProps {}

const TryLogin: React.FC<TryLoginProps> = () => {
  useEffect(() => {
    const token = getCookie(CookieEnum.AUTH_TOKEN);
    const loggedInUser = userVar();
    if (token && !loggedInUser?.id) {
      tryLogin(token).then((user) => {
        if (user) userVar(user);
      });
    }
  }, []);
  return null;
};

export default TryLogin;
