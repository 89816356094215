"use client";

import { useSearchParams } from "next/navigation";
import { useEffect, useRef } from "react";
import { useCartData } from "./useCartData";
import useCountryValidation from "@/shared/utils/useCountryValidation";

interface CartRefCodeProps {}

export function CartRefCode(props: CartRefCodeProps) {
  const hasCalledRef = useRef(false);
  const searchParams = useSearchParams();
  const refCodeQuery = searchParams.get("ref");
  const hasUpdatedShippingDetails = useRef(false);
  const {
    cart,
    setCartRefCode: { setCartRefCode },
    setCartShippingDetails: { setCartShippingDetails },
  } = useCartData();

  const cartRefCode = cart?.refCode;
  const isValid = useCountryValidation(cart?.shippingDetails?.area?.countryId!);

  const defaultVariables = {
    addressLine1: "",
    addressLine2: "",
    area: {
      cityId: "",
      countryId: "",
      regionId: "",
      stateId: "",
    },
    notes: "",
    postalCode: "",
    secondPhone: "",
  };

  useEffect(() => {
    const updateShippingDetails = async () => {
      if (!isValid && !hasUpdatedShippingDetails.current) {
        await setCartShippingDetails({
          shippingDetails: defaultVariables,
        });
        hasUpdatedShippingDetails.current = true;
      } else {
        hasUpdatedShippingDetails.current = false;
      }
    };

    updateShippingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, hasUpdatedShippingDetails.current]);

  useEffect(() => {
    if (
      !hasCalledRef.current &&
      cart?.id &&
      refCodeQuery &&
      cartRefCode != refCodeQuery
    ) {
      hasCalledRef.current = true;
      setCartRefCode({ refCode: refCodeQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartRefCode, refCodeQuery, cart?.id]);

  return null;
}

/**
 this is used only with GoAffPro app, whenever we have a ref code on the query params
 we will send it to the backend to track the cart progress 
 */
