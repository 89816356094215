import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useStateWithTimeout } from "@/hooks/useStateWithTimeout";
import { rtl, themeColor } from "@/shared/styles-utils";
import { ZIndex } from "@/shared/globals/types";

interface LoadingBarProps {
  isLoading: boolean;
}

const LoadingBar: React.FC<LoadingBarProps> = ({
  isLoading: isLoadingProp,
}) => {
  const [isLoading, setIsLoading, setIsLoadingWithTimeout] =
    useStateWithTimeout(false, 1000);

  useEffect(() => {
    if (isLoadingProp) {
      setIsLoading(true);
    } else {
      setIsLoadingWithTimeout(false);
    }
  }, [isLoadingProp, setIsLoading, setIsLoadingWithTimeout]);

  return (
    <StyledWrapper>
      <StyledBar $isLoading={isLoading} />
    </StyledWrapper>
  );
};

export default LoadingBar;

/**
 * Styles
 */

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 4px;
  width: 100%;
  z-index: ${ZIndex["LOADING"]};
`;

const StyledBar = styled.div<{ $isLoading: boolean }>`
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  transform-origin: ${rtl("right", "left")};
  transform: scaleX(0);
  display: block;
  width: 100%;
  background: ${themeColor("primary")};
  height: 4px;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      transform: scaleX(0.99);
      transition: transform 1s;
    `}
`;
