import { useEffect, useState } from "react";
import { Breakpoints } from "../globals/types";

function useBreakPoints() {
  const [breakPoint, setBreakPoint] = useState<
    keyof typeof Breakpoints | "OTHER"
  >("DESKTOP");
  const [didUseEffect, setDidUseEffect] = useState(false);
  function handleResize() {
    if (window.innerWidth < Breakpoints["MOBILE"]) setBreakPoint("MOBILE");
    else if (window.innerWidth < Breakpoints["TABLET"]) setBreakPoint("TABLET");
    else if (window.innerWidth < Breakpoints["SMALLSCREEN"])
      setBreakPoint("SMALLSCREEN");
    else if (window.innerWidth < Breakpoints["DESKTOP"])
      setBreakPoint("DESKTOP");
    else if (window.innerWidth < Breakpoints["WIDESCREEN"])
      setBreakPoint("WIDESCREEN");
    else setBreakPoint("OTHER");
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    setDidUseEffect(true);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    isLoading: !didUseEffect,
    isMobile: breakPoint === "MOBILE",
    isTablet: breakPoint === "TABLET" || breakPoint === "MOBILE",
    isSmallScreen:
      breakPoint === "SMALLSCREEN" ||
      breakPoint === "TABLET" ||
      breakPoint === "MOBILE",
    isDesktop:
      breakPoint === "DESKTOP" ||
      breakPoint === "SMALLSCREEN" ||
      breakPoint === "TABLET" ||
      breakPoint === "MOBILE",
    isWideScreen:
      breakPoint === "WIDESCREEN" ||
      breakPoint === "DESKTOP" ||
      breakPoint === "SMALLSCREEN" ||
      breakPoint === "TABLET" ||
      breakPoint === "MOBILE",
  };
  /* 
    Example: if the window width is 1000px, the hook will return
    {
      isLoading: false,
      isMobile: false,
      isTablet: false,
      isSmallScreen: false,
      isDesktop: true,
      isWideScreen: true
    }
    as the window width is greater than 768px (TABLET) and less than 1024px (DESKTOP) and less than 1224px (WIDESCREEN)
  */
}

export default useBreakPoints;
