"use client";

import React from "react";
import { IntegrationScriptControls } from "../../generated/graphql";
import { createScript } from "./snippets";
import { AnalyticsActions } from "./utils";
import { StoreContextType } from "@/lib/storeData/StoreContext";
import { useAuth } from "@/lib/Authentication/hooks/useAuth";
import useRouteChange from "@/hooks/useRouteChange";

function mapAnalyticsAccount(item) {
  return {
    type: "analytics",
    name: item?.name,
    isActive: item?.isActive,
    id: item?.id,
  };
}

function mapIntegrationsScriptControls(item: IntegrationScriptControls) {
  return {
    type: "store",
    name: item?.provider,
    isActive: true,
    scriptControls: item,
  };
}

interface AnalyticsAccountsProps {
  store: StoreContextType | undefined | null;
}

const AnalyticsAccounts: React.FC<AnalyticsAccountsProps> = ({ store }) => {
  const { user: customer } = useAuth();
  const storeIntegrations = store?.integrationScriptControls;
  const analyticalAccounts = store?.analyticalAccounts;
  const contactInfo = store?.contactInfo;
  const locale = store?.locale;

  useRouteChange(AnalyticsActions.trackRouteChange);

  const analytics = (analyticalAccounts || [])?.map(mapAnalyticsAccount);

  const mappedStoreIntegrations = (storeIntegrations || [])?.map(
    mapIntegrationsScriptControls
  );
  const accounts = [...analytics, ...mappedStoreIntegrations];

  if (!accounts || accounts?.findIndex((account) => account?.isActive) === -1) {
    return null;
  }

  AnalyticsActions.setAccountsMap(accounts);
  const scripts: JSX.Element[] = accounts
    .filter((account) => account?.isActive)
    .map((account) =>
      createScript(account, contactInfo?.email, customer, locale)
    );

  return <>{[...scripts]}</>;
};

export default AnalyticsAccounts;
