import {
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCacheConfig,
} from "@apollo/client";

export const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
  fetchOptions: { next: { revalidate: 10 } }, // revalidate at most every 10s
});

export const cacheConfig: InMemoryCacheConfig = {
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      // as store appearance does not has id, so i will cache it by its name
      case "StoreAppearance":
        return "StoreAppearance";
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    // we may serve the same country in different zones, so i will make each country unique to its zone
    ZoneCountry: {
      keyFields: ["id", "zoneId"],
    },
  },
};
