"use client";

import useRouteChange from "@/hooks/useRouteChange";
import React from "react";
import LoadingBar from "../LoadingBar/LoadingBar";

interface NavigatingBarProps {}

const NavigatingBar: React.FC<NavigatingBarProps> = () => {
  const { isLoading } = useRouteChange();
  return <LoadingBar isLoading={isLoading} />;
};

export default NavigatingBar;
