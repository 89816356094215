"use client";

import { useCartQuery } from "@/generated/graphql";
import { ReactNode, Suspense } from "react";
import { CartRefCode } from "./CartRefCode";
import { CookieEnum, getCookie } from "@/shared/utils/cookie";
import { CartContext } from "./CartContext";
import { useIsBrowser } from "@/hooks/browser-only";

interface CartProviderProps {
  lang: string;
  storeId: string;
  children: ReactNode;
}

export function CartProvider({ children, lang, storeId }: CartProviderProps) {
  const sessionId = getCookie(CookieEnum.SESSION_ID);
  const isBrowser = useIsBrowser();

  // this will be deferred and called only on browser
  const { data, loading } = useCartQuery({
    variables: { storeId },
    context: {
      headers: { locale: lang, sessionId },
      fetchOptions: { next: { revalidate: 0 } },
    },
    skip: !sessionId,
  });

  const isFetching = loading || !isBrowser;

  return (
    <CartContext
      isFetching={isFetching}
      initialCartData={data?.customerQueries?.cart!}
    >
      <Suspense>
        <CartRefCode />
      </Suspense>
      {children}
    </CartContext>
  );
}
