import { useEffect, useState } from "react";

export function useIsBrowser() {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return isBrowser;
}

interface BrowserOnlyProps {
  children: React.ReactNode;
}
export function BrowserOnly(props: BrowserOnlyProps) {
  const isBrowser = useIsBrowser();
  if (!isBrowser) {
    return null;
  }
  return props.children;
}
