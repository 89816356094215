import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export default function useRouteChange(cb?: (pathname: string) => void) {
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(pathname);

  useEffect(() => {
    if (url === pathname) {
      setIsLoading(false);
      return;
    }

    cb?.(pathname);

    setUrl(pathname);
    setIsLoading(true);
  }, [cb, pathname, url]);

  return { isLoading, url };
}
